import React, { Component } from 'react'

export default class PayButton extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="BQZF7KTFB62Y2" />
        <input type="hidden" name="on0" value="Paquetes" />
        <input type="hidden" name="os0" value={this.props.value} />
        <input type="hidden" name="currency_code" value="MXN" />
        <button class="button is-primary" type="submit">
          Contratar
        </button>
        <img
          alt=""
          border="0"
          src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    )
  }
}
