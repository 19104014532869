import React from 'react'
import Layout from '../components/layout'
import PayButton from '../components/PayButton'

const Facturacion = () => (
  <Layout>
    <div className="container">
      <h1 className="title is-3 has-text-centered dark-grey">Facturación</h1>
      <p className="subtitle is-4 has-text-centered dark-grey">
        Conoce nuestros paquetes de facturación
      </p>
      <div className="grid">
        <div className="box price">
          <h2 className="title is-4">PYME 1</h2>
          <p className="subtitle is-5">25 folios</p>
          <h3 className="title is-3">$ 250.00</h3>
        </div>
        <div className="box price">
          <h2 className="title is-4">PYME 2</h2>
          <p className="subtitle is-5">50 folios</p>
          <h3 className="title is-3">$ 400.00</h3>
        </div>
        <div className="box price">
          <h2 className="title is-4">PYME 3</h2>
          <p className="subtitle is-5">75 folios</p>
          <h3 className="title is-3">$ 525.00</h3>
        </div>
        <div className="box price">
          <h2 className="title is-4">PYME 4</h2>
          <p className="subtitle is-5">100 folios</p>
          <h3 className="title is-3">$ 600.00</h3>
        </div>
        <div className="box price">
          <h2 className="title is-4">Mini</h2>
          <p className="subtitle is-5">150 folios</p>
          <h3 className="title is-3">$ 799.00</h3>
        </div>
        <div className="box price">
          <h2 className="title is-4">Micro</h2>
          <p className="subtitle is-5">300 folios</p>
          <h3 className="title is-3">$ 999.00</h3>
        </div>
      </div>
    </div>
  </Layout>
)

export default Facturacion
